import React, { useState } from 'react';
import logo from './images/mcp.jpg';
import './App.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';



function App() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="App">
  
      <header className="App-header">
        <div className="holdheader ">
          <Header />
        </div>
      </header>

      <resume >
    
     
      <Tabs value={value} onChange={handleChange} className='tabs' variant="fullWidth">
            <Tab label="Technical Skills" />
            <Tab label="Employment" />
            <Tab label="Certifications" />
            <Tab label="Education" />
            <Tab label="Other Responsibilities" />
          </Tabs>
   
      {value === 0 && <div><TechSkills /></div>}
      {value === 1 && <div><Employment /></div>}
      {value === 2 && <div><Certifications /></div>}
      {value === 3 && <div><Education /></div>}
      {value === 4 && <div><OtherResponsibilies /></div>}
    

       
        
        
        
        
      </resume>
      
    </div>
  );
}
function Employment() {
  const employment = [
    {company:"Grey Wolf Systems",jobtitle:"Senior Application Developer",date:"12/2014-8/2024",
      details:[
        "Responsible for maintaining and enhancing current products while spearheading the development of new features in line with company vision.","Custom software design and development including gathering user requirements, writing proposals, project management, and developing web and desktop solutions for new and existing customers.","API integrations with various ERP systems including SX.e and Epicor Prophet21 through in-house API development.","Mentor junior staff in code standards, troubleshooting, and problem-solving.",
        "Led project with distributed staff to develop and maintain Magento and Pimcore modules to integrate ERP systems with E-Commerce and Product Information Management solutions.","C#, PHP, Progress 4GL, VB.NET, HTML, Javascript/JQuery,Epicor Prophet21 Dynachange Business Rules, Portals, and Screen Designer" 

    ]},
    {company:"Delta Defense LLC",jobtitle:"Director of State Compliance and Business Development",date:"8/2013-11/2014",
      details:[
        "Launched new division of company.  Developed relationships with regulatory authorities and managed compliance regulations in 30+ states.","Built relationships with state lobbying organizations and third-party vendors.","Developed instructor certification curriculum and standards.  Trained nationwide self-defense instructors on teaching and business best practices."
   
    ]},
    {company:"Delta Defense LLC",jobtitle:"Software Engineer",date:"11/2012-8/2014",
      details:[
        "Developed and maintained custom implementation of FluidCommerce store across multiple websites.","Coordinated development requests from marketing and member services teams.","Implemented KISSMetrics analytics.  Coordinated with SEO and ad management contractors.","Designed and implemented custom Wordpress theme and plugins." ]},
    {company:"Computer Pundits Corporation",jobtitle:"Senior Software Engineer",date:"2/2006-11/2012",
      details:[
        "Designed, developed, and maintained extensive cataloging software package. ",
        "Coordinated customer requests, technical support, and third-party vendors.  ",
        "Led on-site and remote-office development teams.  ",
        "Developed and extended user-configurable e-commerce package, integrated software with SX.enterprise and Epicor Eclipse.  ",
        "Assisted sales team with live demonstrations, custom software requirements analysis, and proposal preparation.",
        "Migrated legacy application from Visual Basic 6 to .NET."
        
    ]},
    {company:"Intellirisk Management Corp.",jobtitle:"FACS System Administrator",date:"4/2002-12/2005",
      details:[
        "Administered FACS collection system, ACCESS, and MySQL databases. ", 
        "Designed custom software applications using M/MUMPS and VBA.  ",
        "Miscellaneous support for various Microsoft Office applications.", 
        "Primary contact with third party vendors."
    ]}

  ];
  return (
    <div className="employment">
      <h2>Employment</h2>
      <div className="datawrapper">
        {employment.map((item) => (
          <>
          <div className="floatleft">
            <i>{item.jobtitle}, {item.company}</i>
          </div>
          <div className="floatright">
            <i>{item.date}</i>
          </div>
          <div className="clearBoth">
            <ul>
            {item.details.map((detail) => (
              <li>{detail}</li>
            ))}
            </ul>
          </div>
          </>
        ))}
      </div>
    </div>
  )
}
function Certifications() {
  const certs =[
    {cert:"Microsoft Certified Professional, Designing and Implementing Desktop Applications with Microsoft VB 6.0 ",date:"August 2001"},
    {cert:"Google Adwords Individual Qualification",date:"October 2013"},
    {cert:"American Collectors Association Certified Collector",date:"June 2001"}
  ];
  return (
    <div className="certifications">
      <h2>Certifications</h2>
      <div className="datawrapper">
        {certs.map((item) => (
          <>
          <div className="floatleft clearBoth">
            {item.cert}
          </div>
          <div className="floatright">
            <i>{item.date}</i>
          </div>
          </>
        ))
        
        }
      </div>
    </div>
  )
}
function Education() {
  const education = [
    {school:"KRS Computer & Business School, Bloomington, MN",year:"2001",degree:"Diploma, MCSD/Visual Basic"}
  ];
  return (
    <div className="education">
      <h2>Education</h2>
      <div className="datawrapper">
        {education.map((item) => (
          <>
          <div className="floatleft">
            {item.degree}
          </div>
          <div className="floatright">
            <i>{item.year}</i>
          </div>
          <div className="floatleft clearBoth">
            {item.school}
          </div>
          </>
        ))
        
        }
      </div>
    </div>
  )
}
function OtherResponsibilies() {
  const responsibilities = [
    {title:"Scoutmaster",organization:"Scouts BSA",date:"5/2019-Present",
    responsibilities:[
      "Founded one of the first Scouts BSA (Boy Scouts of America) Troops for girls in Minnesota.",
      "Merit badge counselor for the Programming merit badge.",
      "Merit badge counselor for the Citizenship in Society merit badge, BSA’s Diversity, Equity, and Inclusion offering.",
      "Member: Special Needs & Disabilities Committee",
      "Member: STEM sub-committee",
      "Member: Outdoor Ethics Team"
    ]},
  {title:"Technology Committee",organization:"Community Emergency Response Team",date:"10/2023-Present",
    responsibilities:[
      "Assist community during disasters and emergencies.",
      "Manage equipment and software for team"
      
    ]},
  ];
  return (
    <div className="otherresponsibilies">
      <h2>Other Responsibilities</h2>
      <div className="datawrapper">
        {responsibilities.map((item) => (
          <>
          <div className="floatleft">
            <i>{item.title}, {item.organization}</i>
          </div>
          <div className="floatright">
            <i>{item.date}</i>
          </div>
          <div className="clearBoth">
            <ul>
            {item.responsibilities.map((responsibility) => (
              <li>{responsibility}</li>
            ))}
            </ul>
          </div>
          </>
        ))}
      </div>
    </div>
  )
}
function TechSkills() {
  const languageskills = ["ASP.NET/ASP","C#","HTML/CSS","Javascript/JQuery","M/MUMPS","Node Red","PHP","Progress 4GL",
    "Prophet21 Business Rules","Python","Symfony","Turbo Pascal","VBScript","Visual Basic.NET","Visual Basic 6.0","Visual Basic for Applications (VBA)"];
  const dbskills=["Access","ElasticSearch","InfluxDB","MariaDB","MeiliSearch","MS SQL Server","MySQL","Oracle","Progress","SQLite","SSIS","SSRS"];
  const otherskills=["API/Web Services Development","ECommerce API Usage","EDI/Electronic Data Interchange",
    "FACS Event Tactics/Report Writer/Enable","FluidCommerce","Git & Team Foundation Server","Google Analytics/Adwords","Internet Information Services","Magento Development","MQTT Message Queuing","PHPMyAdmin","State Regulatory Compliance","System Integration","Wordpress Theme & Plugin Development"];
  return (
    <div className="techskills">
      <h2>Technical Skills</h2>
      <div className="datawrapper">
        <SkillList skills={languageskills} title="Languages and Frameworks"/>
        <SkillList skills={dbskills} title="Database Systems & Tools"/>
        <SkillList skills={otherskills} title="Other Skills"/>
      </div>
    </div>
  )
}
function SkillList(props) {
  return (
    <div className="skilllist">
      <h3>{props.title}</h3>
      <ul>
      { props.skills.map((item) => <li>{item}</li>)}
    </ul>
    </div>
  )
}
function Header() {
  return (
    <div className="header">
      <Contact />
      <Cert />
    </div>
  )
}

function Contact() {
  return (
    <div className="contact">
      <h1>Jason Walberg</h1>
      <h2>Software Engineer</h2>
    </div>
  )
}

function Cert() {
  return (
    <div className="cert">
      <img src={logo} className="App-logo" alt="Microsoft Certified Professional" />
    </div>
  )
}
export default App;
